export const NAV_ITEMS = [
  "Home",
  "Electronics",
  "Books",
  "Music",
  "Movies",
  "Clothing",
  "Games",
  "Furniture",
  "Travel",
  "Botanical",
  "Category name",
];
